
import Vue from 'vue';
import axios from 'axios';
import { getGroupInfo, createModifyGoodsForInfra } from '@/api/infra-renew/index';
import { ModifyPackage } from '@/api/gabia-proxy/types';
import { getDateStringFromFormat } from '@/utils/common.js';
import NimsModifyStep01Template from '@/templates/nims/NimsModifyStep01Template.vue';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import { getPackagesSearch, modifyProductForNims, deleteNimsOrderProxy } from '@/api/gabia-proxy';
import { getMainGoodsInfo } from '@/api/service-external';
const NIMS_APPLY_ERROR = 'NIMS_APPLY_ERROR';
const GOODS_ORDER_APPLY_ERROR = 'GOODS_ORDER_APPLY_ERROR';
const NIMS_EXPIRE_GROUP_ERROR = '그룹 만기일이 존재하지 않아 상품변경이 불가합니다.';
const PRODUCT_STATUS_OK = '9';

export default Vue.extend({
  name: 'NimsModifyStep01Brain',
  components: { CalculatorScreen, NimsModifyStep01Template },
  props: {},
  data() {
    return {
      isRender: false,
      formState: {
        terms: {
          agree1: false,
        },
        period: '1',
        startDate: '',
        groupExpireDate: '',
        selectedProductId: 0,
        currentTargetGoodsId: 0,
        currentTargetGoodsExposeName: '',
      },
      groupSeqNo: '',
      mainProductSeqNo: '',
      currentPackage: {} as ModifyPackage,
      limitDate: '',
      order_seqno: '',
      order_number: '',
    };
  },
  computed: {
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  async mounted() {
    this.groupSeqNo = this.$route.query?.group ? String(this.$route.query?.group) : '';
    this.mainProductSeqNo = this.$route.query?.seq_no ? String(this.$route.query?.seq_no) : '';
    this.formState.startDate = getDateStringFromFormat('yyyy-mm-dd');

    try {
      if (this.groupSeqNo && this.mainProductSeqNo) {
        await this.getGroupInfoFnc();
        await this.getMainServiceGoodsInfoFnc();
        await this.getPackagesSearchFnc();
      }

      this.isRender = true;
      this.$GlobalLoading.hide();
    } catch (error) {
      const errorObj = error as Error;
      const errorCode = errorObj.message;
      alert(errorCode);
    }
  },
  methods: {
    async goNextStep() {
      if (!this.formState.terms.agree1) {
        alert('모든 약관에 동의해주세요.');
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        return;
      }

      try {
        this.$GlobalLoading.show();
        await this.postChagneProduct();
        await this.createInfraChangeOrder();
        this.$GlobalLoading.hide();

        const returnMsg =
          process.env.VUE_APP_MODE === 'production'
            ? '결제 페이지로 이동합니다.'
            : `결제 페이지로 이동합니다.  3단계 payment_seqno=${this.order_seqno}  * 해당메세지는 스테이징 이후에는 안나옵니다.`;

        alert(returnMsg);

        this.$router.push({
          name: 'modify-02',
          params: { service_type: 'nims' },
          query: { seq_no: this.mainProductSeqNo, payment_seqno: this.order_seqno },
        });
        // https://temp-application.gabia.com:8080/ems-step-01/nims?payment_seqno=11870091
      } catch (error) {
        const errorObj = error as Error;
        const errorCode = errorObj.message;

        if (errorCode === NIMS_APPLY_ERROR) {
          //alert('상품변경에서 오류가 발생했습니다.');
          await this.deleteOrder();
        } else if (errorCode === GOODS_ORDER_APPLY_ERROR) {
          alert('주문서 생성에서 오류가 발생했습니다.');
        }

        this.$GlobalLoading.hide();
      }
    },
    chageLimitDate(date_str: string) {
      this.limitDate = date_str;
    },
    async getMainServiceGoodsInfoFnc() {
      try {
        const { data } = await getMainGoodsInfo(this.mainProductSeqNo, 'H');

        if (data.mainGoods.status !== PRODUCT_STATUS_OK) {
          alert('처리완료 상태의 상품만 상품 변경을 신청할 수 있습니다.');
          window.history.back();
          return;
        }
        this.formState.currentTargetGoodsId = data.mainGoods.mainServiceGoods.goodsId;
        this.formState.currentTargetGoodsExposeName = data.mainGoods.mainServiceGoods.goods.expose_name;
      } catch (error) {
        console.warn(error);
      }
    },
    async getPackagesSearchFnc() {
      const params = {
        uri: '/v4/type/packages/search',
        'data[search[label][column]]': 'type:name',
        'data[search[label][operator]]': 'and',
        'data[search[label][data]]': 'NIMS_DEPCODE:NIMS_C',
        'data[relations[]]]': 'goods',
        'data[paging[per_page]]': '50',
        'data[paging[page]]': '1',
      };

      try {
        const { data } = await getPackagesSearch(params);

        const targetPackage = data.data.filter((item) =>
          item.goods.some((subItem) => subItem.id === this.formState.currentTargetGoodsId),
        );

        if (!targetPackage.length) {
          alert('해당상품은 다른상품으로 변경이 불가능합니다.');
          window.history.back();
          return;
        } else {
          this.currentPackage = targetPackage[0];
          this.formState.selectedProductId =
            this.formState.currentTargetGoodsId !== targetPackage[0].goods[0].id
              ? targetPackage[0].goods[0].id
              : targetPackage[0].goods[1].id;
        }
      } catch (error) {
        console.warn(error);
      }
    },
    async postChagneProduct() {
      const params = {
        uri: '/v5/add-change/order',
        data: {
          user_id: this.userId,
          checkout: {
            total_price: 0,
            settlement_option: {
              dep_item: 'IMS_C',
            },
            main_goods_data: {
              install_date: this.formState.startDate,
            },
            service: {
              seqno: this.groupSeqNo,
            },
            add_goods_list: [],
            change_goods_list: [
              {
                service_main_goods_seqno: this.mainProductSeqNo,
                change_goods_id: this.formState.selectedProductId,
                goods_type: 'main',
                sort_order: 1,
              },
            ],
          },
        },
      };
      try {
        const { data } = await modifyProductForNims(params);
        this.order_seqno = data.seqno;
        this.order_number = data.order_number;
      } catch (error) {
        console.warn(error);
        throw new Error(GOODS_ORDER_APPLY_ERROR);
      }
    },
    async createInfraChangeOrder() {
      try {
        await createModifyGoodsForInfra(this.mainProductSeqNo, {
          payment_seqno: this.order_seqno,
          user_id: this.userId,
          request_date: this.formState.startDate,
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const { error } = err.response?.data;
          alert(error || NIMS_APPLY_ERROR);
        }

        throw new Error(NIMS_APPLY_ERROR);
      }
    },
    async deleteOrder() {
      const params = {
        uri: '/mygabia/payment/order',
        data: {
          user_id: this.userId,
          ordernum_list: this.order_number,
        },
      };

      try {
        const response = await deleteNimsOrderProxy(params);
        alert('주문서 취소에 성공');
      } catch (error) {
        alert('주문서 취소에 실패했습니다.');
      }
    },
    async getGroupInfoFnc() {
      const { data } = await getGroupInfo(parseInt(this.groupSeqNo));
      this.formState.groupExpireDate = data.data.group_expire_date ? data.data.group_expire_date.substring(0, 10) : '';

      if (this.formState.groupExpireDate === '') {
        throw new Error(NIMS_EXPIRE_GROUP_ERROR);
      }
    },
  },
});
