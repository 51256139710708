
// https://temp-application.gabia.com:8080/modify-step-01/nims?group=93721
import Vue, { PropType } from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import Step01Modals from './fragments/Step01Modals.vue';
import { ModifyPackage, ModifyGood } from '@/api/gabia-proxy/types';
import { getDateStringFromFormat, getDateStringFromObject } from '@/utils/common.js';
export default Vue.extend({
  name: 'NimsModifyStep01Template',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout, Step01Modals },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          period: '1',
          groupExpireDate: '',
          selectedProductId: 0,
          currentTargetGoodsId: 0,
          currentTargetGoodsExposeName: '',
          terms: {
            agree1: false,
          },
        };
      },
    },
    currentPackage: {
      type: Object as PropType<ModifyPackage>,
      default: () => {
        return {
          id: 0,
          name: '',
          expose_name: '',
          status: 'Y',
          package_keep_flag: 'Y',
          created_at: '',
          updated_at: '',
          deleted_at: null,
          goods: [],
        };
      },
    },
  },
  data() {
    return {
      mainProductSeqNo: '',
      agreeAll: false,
      settlementInfo: {
        startDate: '',
        tempSelectedGoodsId: '',
      },
      formTitle,
    };
  },
  computed: {
    currentTempGoodsInfo(): ModifyGood {
      return (
        this.currentPackage.goods.filter((item) => {
          return item.id === parseInt(this.settlementInfo.tempSelectedGoodsId);
        })[0] || { retail_price: '' }
      );
    },
    calculateDaysBetweenDatesHasGroup(): number {
      if (!this.settlementInfo.startDate) {
        return 0;
      }

      const d1 = new Date(this.settlementInfo.startDate) as any;
      const d2 = new Date(this.formState.groupExpireDate) as any;

      // 날짜 차이 계산 (밀리초 단위)
      const timeDiff = d2 - d1;

      // 밀리초를 일로 변환 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
      const daysDiff = timeDiff / (24 * 60 * 60 * 1000);

      return Math.abs(daysDiff); // 절대값을 반환하여 음수 결과 방지
    },
    contractLimitDate(): string {
      if (!this.settlementInfo.startDate) {
        return '';
      }

      const limitYear = parseInt(this.settlementInfo.startDate.substring(0, 4)) + 2;
      const limitMonthDate = this.settlementInfo.startDate.substring(4);

      return `${limitYear}${limitMonthDate}`;
    },
    currentDate(): string {
      return getDateStringFromFormat('yyyy-mm-dd');
    },
    calenderLimitDate(): string {
      let date = new Date(this.formState.groupExpireDate);
      date.setDate(date.getDate() - 2);

      return getDateStringFromObject({ format: 'yyyy-mm-dd', setDate: date });
    },
    agreeBoth(): boolean {
      return !!this.formState.terms.agree1;
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },

  mounted() {
    this.settlementInfo.startDate = this.formState.startDate;
    this.settlementInfo.tempSelectedGoodsId = this.formState.selectedProductId;
    this.mainProductSeqNo = this.$route.query?.seq_no ? String(this.$route.query?.seq_no) : '';
  },
  methods: {
    openModal(modal_name) {
      this.$GabiaModal.show(modal_name);
    },
    onChangeStartDate(event) {
      const { value } = event.target;
      this.$emit('change', {
        ...this.formState,
        startDate: String(value),
      });
      //this.$emit('calculate-price');
    },
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
          },
        });
      }
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      const params = {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      };
      this.$emit('change', params);
    },
    onChageFormState(property_name, event) {
      if (property_name === 'selectedProductId') {
        this.$emit('change', {
          ...this.formState,
          [property_name]: parseInt(event.target.value),
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          [property_name]: event.target.value,
        });
      }
    },
  },
});
