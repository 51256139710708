import { instance } from '../instance';
import { ServiceMainGoods } from './types';
import { AxiosPromise } from 'axios';
const BASEURL = process.env.VUE_APP_SERVICE_EXTERNAL_API;

const serviceExternalAPI = instance.createWithoutInterceptors(BASEURL, {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

/**
 * @description 주상품 단일조회 API https://confluence.gabia.com/pages/viewpage.action?pageId=96514448
 * @param serviceNo : 주상품 번호
 * @param serviceType : H: 호스팅 , D: 도메인
 * @returns
 */

export function getMainGoodsInfo(serviceNo: string, serviceType: 'H' | 'D'): AxiosPromise<ServiceMainGoods> {
  return serviceExternalAPI.get(`/main-goods/${serviceType}/${serviceNo}?withGoodsDetail=Y`);
}
